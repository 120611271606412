@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url('Inter-Light.woff2?v=3.19') format('woff2'),
    url('Inter-Light.woff?v=3.19') format('woff');
}
@font-face {
  font-family: 'Inter';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url('Inter-LightItalic.woff2?v=3.19') format('woff2'),
    url('Inter-LightItalic.woff?v=3.19') format('woff');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('Inter-Regular.woff2?v=3.19') format('woff2'),
    url('Inter-Regular.woff?v=3.19') format('woff');
}
@font-face {
  font-family: 'Inter';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url('Inter-Italic.woff2?v=3.19') format('woff2'),
    url('Inter-Italic.woff?v=3.19') format('woff');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url('Inter-Medium.woff2?v=3.19') format('woff2'),
    url('Inter-Medium.woff?v=3.19') format('woff');
}
@font-face {
  font-family: 'Inter';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url('Inter-MediumItalic.woff2?v=3.19') format('woff2'),
    url('Inter-MediumItalic.woff?v=3.19') format('woff');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url('Inter-SemiBold.woff2?v=3.19') format('woff2'),
    url('Inter-SemiBold.woff?v=3.19') format('woff');
}
@font-face {
  font-family: 'Inter';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url('Inter-SemiBoldItalic.woff2?v=3.19') format('woff2'),
    url('Inter-SemiBoldItalic.woff?v=3.19') format('woff');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('Inter-Bold.woff2?v=3.19') format('woff2'),
    url('Inter-Bold.woff?v=3.19') format('woff');
}
@font-face {
  font-family: 'Inter';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url('Inter-BoldItalic.woff2?v=3.19') format('woff2'),
    url('Inter-BoldItalic.woff?v=3.19') format('woff');
}
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url('Inter-Black.woff2?v=3.19') format('woff2'),
    url('Inter-Black.woff?v=3.19') format('woff');
}
@font-face {
  font-family: 'Inter';
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: url('Inter-BlackItalic.woff2?v=3.19') format('woff2'),
    url('Inter-BlackItalic.woff?v=3.19') format('woff');
}
// Override App Less Variables
// ---
@font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI',
  'PingFang SC', 'Hiragino Sans GB';

@primary-color: #0066cc;
@success-color: #00cf86;
@info-color: #0b00d9;
@warning-color: #ff9700;
@error-color: #f83e5a;
@highlight-color: #f83e5a;
@processing-color: @primary-color;
@body-background: #f2f6fa;
@component-background: #ffffff;
@heading-color: #17324d;
@text-color: #17324d;
@text-color-secondary: #455b71;
@text-selection-bg: #0066cc;
@border-color-base: #c2c7cc;
@border-color-split: #e6e9f2;
@layout-body-background: #f2f6fa;
@layout-header-background: #ffffff;
@layout-trigger-background: #ffffff;

@table-header-bg: #0066cc;
@table-header-color: #ffffff;
@table-header-sort-bg: #004d99;

@table-header-sort-active-bg: #0253a4;
@table-fixed-header-sort-active-bg: #0253a4;
@table-header-filter-active-bg: #0253a4;

@table-header-icon-color: #b0d4f7;
@table-header-icon-color-hover: #e0eefc;

@table-padding-vertical: 12px;
@table-padding-horizontal: 12px;

@layout-header-padding: 0 12px;
@layout-header-height: 64px;

// @font-size-base: 14px; // 14px

// border radius
@border-radius-base: 4px; // 2px;
@border-radius-sm: 2px; // @border-radius-base;

// vertical paddings
// @padding-lg: 24px; // containers
// @padding-md: 16px; // small containers and buttons
// @padding-sm: 12px; // Form controls and items
// @padding-xs: 8px; // small items
// @padding-xss: 4px; // more small

// // vertical padding for all form controls
// @control-padding-horizontal: @padding-sm;
// @control-padding-horizontal-sm: @padding-xs;

// vertical margins
// @margin-lg: 24px; // containers
// @margin-md: 16px; // small containers and buttons
// @margin-sm: 12px; // Form controls and items
// @margin-xs: 8px; // small items
// @margin-xss: 4px; // more small

// height rules
// @height-base: 32px;
// @height-lg: 40px;
// @height-sm: 24px;

@btn-font-weight: 500;
// @btn-height-base: 40px;
// @btn-height-lg: 48px;
// @btn-height-sm: 28px;
@btn-font-size-lg: 14px; //@font-size-lg;
@btn-padding-horizontal-lg: 23px; // 14 - 1px

// @dropdown-line-height: 30px;

@checkbox-size: 18px;
@radio-size: 18px;

// @input-height-base: 40px;
// @input-height-lg: 56px;
// @input-height-sm: 28px;
@disabled-color: #b0c0d1;

// Anchor
// ---
// @anchor-bg: transparent;
@anchor-border-color: transparent; //@border-color-split;
// @anchor-link-top: 7px;
// @anchor-link-left: 16px;
// @anchor-link-padding: @anchor-link-top 0 @anchor-link-top @anchor-link-left;
// Override App Less Variables
// ---
@font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI',
  'PingFang SC', 'Hiragino Sans GB';

@primary-color: #0066cc;
@success-color: #00cf86;
@info-color: #0b00d9;
@warning-color: #ff9700;
@error-color: #f83e5a;
@highlight-color: #f83e5a;
@processing-color: @primary-color;
@body-background: #f2f6fa;
@component-background: #ffffff;
@heading-color: #17324d;
@text-color: #17324d;
@text-color-secondary: #455b71;
@text-selection-bg: @primary-color;
@border-color-base: #c2c7cc;
@border-color-split: #e6e9f2;
@layout-body-background: #f2f6fa;
@layout-header-background: #ffffff;
@layout-trigger-background: #ffffff;

@table-header-bg: @primary-color;
@table-header-color: #ffffff;
@table-header-sort-bg: darken(@primary-color, 10%);

@table-header-sort-active-bg: darken(@primary-color, 8%);
@table-fixed-header-sort-active-bg: darken(@primary-color, 8%);
@table-header-filter-active-bg: darken(@primary-color, 8%);

@table-header-icon-color:lighten (@primary-color,48%); // #b0d4f7;
@table-header-icon-color-hover: lighten (@primary-color, 55%); // #e0eefc;

@table-padding-vertical: 12px;
@table-padding-horizontal: 12px;

@layout-header-padding: 0 12px;
@layout-header-height: 64px;

// @font-size-base: 14px; // 14px

// border radius
@border-radius-base: 4px; // 2px;
@border-radius-sm: 2px; // @border-radius-base;

// vertical paddings
// @padding-lg: 24px; // containers
// @padding-md: 16px; // small containers and buttons
// @padding-sm: 12px; // Form controls and items
// @padding-xs: 8px; // small items
// @padding-xss: 4px; // more small

// // vertical padding for all form controls
// @control-padding-horizontal: @padding-sm;
// @control-padding-horizontal-sm: @padding-xs;

// vertical margins
// @margin-lg: 24px; // containers
// @margin-md: 16px; // small containers and buttons
// @margin-sm: 12px; // Form controls and items
// @margin-xs: 8px; // small items
// @margin-xss: 4px; // more small

// height rules
// @height-base: 32px;
// @height-lg: 40px;
// @height-sm: 24px;

@btn-font-weight: 500;
// @btn-height-base: 40px;
// @btn-height-lg: 48px;
// @btn-height-sm: 28px;
@btn-font-size-lg: 14px; //@font-size-lg;
@btn-padding-horizontal-lg: 23px; // 14 - 1px

// @dropdown-line-height: 30px;

@checkbox-size: 18px;
@radio-size: 18px;

// @input-height-base: 40px;
// @input-height-lg: 56px;
// @input-height-sm: 28px;
@disabled-color: #b0c0d1;

// Anchor
// ---
// @anchor-bg: transparent;
@anchor-border-color: transparent; //@border-color-split;
// @anchor-link-top: 7px;
// @anchor-link-left: 16px;
// @anchor-link-padding: @anchor-link-top 0 @anchor-link-top @anchor-link-left;

