.form-field-item {
  margin-top: 10px;

  // Gestione altezze dei campi Radio e Checkbox
  // delle Dichiarazioni che sono inseriti come singola riga.
  &.radio-checkbox {
    margin-top: 0;

    .ant-form-item-control-input {
      min-height: 26px; // antd 32px
      margin-bottom: 6px; // antd non impostato
    }

    .ant-form-item-explain {
      min-height: 0px; // antd 32px
    }
  }

  // Label Material Stile
  .ant-form-item-label {
    position: absolute;
    margin-left: 8px;
    margin-top: -12px;
    z-index: 2;
    padding: 0;

    > label {
      font-size: 12px;
      padding: 0 4px;
      background-color: #fff;
      color: @text-color-secondary;
    }
  }

  &.ghost {
    border-bottom: solid 1px transparent;
  }

  .ant-form-item-control {
    flex: 1 1 auto;
    // commento perche taglia l'evidenzazione > Verificare ellipsiss ??
    // overflow: hidden; // aggiunto per gestire l'ellipsiss
    .ant-form-item-control-input {
      padding-left: 12px;
      padding-top: 6px;
      font-size: 16px;
    }
  }

  // per spostare l'etichetta richiesta dopo la label
  // .ant-form-item-label {
  //   > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
  //     display: inline-block;
  //     margin: 0;
  //     margin-left: 4px;
  //     color: #f83e5a;
  //     font-size: 14px;
  //     font-family: sans-serif;
  //     line-height: 1;
  //     content: '*';
  //   }
  //   > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  //     display: none;
  //     content: '';
  //   }
  // }

  &.editable {
    .ant-form-item-control {
      .ant-form-item-control-input {
        padding-left: 0;
        padding-top: 0;
      }
    }
  }

  // versione non editabile
  &.not-editable:not(.radio-checkbox) {
    // commento lo spostemanto dlla label a sinistra
    // spostiamo l'etichetta a sinistra
    // .ant-form-item-label {
    //   margin-left: 0px;
    //   > label {
    //     padding: 0px;
    //   }
    // }
    // spostiamo il testo a sinistra
    // e inseriamo una riga inferiore per delimitare il campo
    .ant-form-item-control {
      .ant-form-item-control-input {
        min-height: 40px;
        color: @text-color;
        line-height: 1.3;
        background-color: @component-background;
        background-image: none;
        border-bottom: 1px solid @border-color-base;
        border-top: 1px solid @border-color-base;
        // border-left: 1px solid @border-color-base;
        // border: 1px solid @border-color-split;
        border-radius: 0px;
        transition: all 0.3s;
        // padding: 6.5px 11px;
        // commento lo spostemanto dlla label a sinistra
        // padding: 8px 11px 5px 0px;
        padding: 8px 11px 5px 11px;
        font-size: 16px;
      }
    }
  }
  &.not-editable.radio-checkbox {
    .ant-form-item-control {
      .ant-form-item-control-input {
        padding-left: 0;
        padding-top: 0;
      }
    }
  }

  &.help-in-tooltips {
    margin-bottom: 0;
  }
}
// Override App Less Variables
// ---
@font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI',
  'PingFang SC', 'Hiragino Sans GB';

@primary-color: #0066cc;
@success-color: #00cf86;
@info-color: #0b00d9;
@warning-color: #ff9700;
@error-color: #f83e5a;
@highlight-color: #f83e5a;
@processing-color: @primary-color;
@body-background: #f2f6fa;
@component-background: #ffffff;
@heading-color: #17324d;
@text-color: #17324d;
@text-color-secondary: #455b71;
@text-selection-bg: #0066cc;
@border-color-base: #c2c7cc;
@border-color-split: #e6e9f2;
@layout-body-background: #f2f6fa;
@layout-header-background: #ffffff;
@layout-trigger-background: #ffffff;

@table-header-bg: #0066cc;
@table-header-color: #ffffff;
@table-header-sort-bg: #004d99;

@table-header-sort-active-bg: #0253a4;
@table-fixed-header-sort-active-bg: #0253a4;
@table-header-filter-active-bg: #0253a4;

@table-header-icon-color: #b0d4f7;
@table-header-icon-color-hover: #e0eefc;

@table-padding-vertical: 12px;
@table-padding-horizontal: 12px;

@layout-header-padding: 0 12px;
@layout-header-height: 64px;

// @font-size-base: 14px; // 14px

// border radius
@border-radius-base: 4px; // 2px;
@border-radius-sm: 2px; // @border-radius-base;

// vertical paddings
// @padding-lg: 24px; // containers
// @padding-md: 16px; // small containers and buttons
// @padding-sm: 12px; // Form controls and items
// @padding-xs: 8px; // small items
// @padding-xss: 4px; // more small

// // vertical padding for all form controls
// @control-padding-horizontal: @padding-sm;
// @control-padding-horizontal-sm: @padding-xs;

// vertical margins
// @margin-lg: 24px; // containers
// @margin-md: 16px; // small containers and buttons
// @margin-sm: 12px; // Form controls and items
// @margin-xs: 8px; // small items
// @margin-xss: 4px; // more small

// height rules
// @height-base: 32px;
// @height-lg: 40px;
// @height-sm: 24px;

@btn-font-weight: 500;
// @btn-height-base: 40px;
// @btn-height-lg: 48px;
// @btn-height-sm: 28px;
@btn-font-size-lg: 14px; //@font-size-lg;
@btn-padding-horizontal-lg: 23px; // 14 - 1px

// @dropdown-line-height: 30px;

@checkbox-size: 18px;
@radio-size: 18px;

// @input-height-base: 40px;
// @input-height-lg: 56px;
// @input-height-sm: 28px;
@disabled-color: #b0c0d1;

// Anchor
// ---
// @anchor-bg: transparent;
@anchor-border-color: transparent; //@border-color-split;
// @anchor-link-top: 7px;
// @anchor-link-left: 16px;
// @anchor-link-padding: @anchor-link-top 0 @anchor-link-top @anchor-link-left;
// Override App Less Variables
// ---
@font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI',
  'PingFang SC', 'Hiragino Sans GB';

@primary-color: #0066cc;
@success-color: #00cf86;
@info-color: #0b00d9;
@warning-color: #ff9700;
@error-color: #f83e5a;
@highlight-color: #f83e5a;
@processing-color: @primary-color;
@body-background: #f2f6fa;
@component-background: #ffffff;
@heading-color: #17324d;
@text-color: #17324d;
@text-color-secondary: #455b71;
@text-selection-bg: @primary-color;
@border-color-base: #c2c7cc;
@border-color-split: #e6e9f2;
@layout-body-background: #f2f6fa;
@layout-header-background: #ffffff;
@layout-trigger-background: #ffffff;

@table-header-bg: @primary-color;
@table-header-color: #ffffff;
@table-header-sort-bg: darken(@primary-color, 10%);

@table-header-sort-active-bg: darken(@primary-color, 8%);
@table-fixed-header-sort-active-bg: darken(@primary-color, 8%);
@table-header-filter-active-bg: darken(@primary-color, 8%);

@table-header-icon-color:lighten (@primary-color,48%); // #b0d4f7;
@table-header-icon-color-hover: lighten (@primary-color, 55%); // #e0eefc;

@table-padding-vertical: 12px;
@table-padding-horizontal: 12px;

@layout-header-padding: 0 12px;
@layout-header-height: 64px;

// @font-size-base: 14px; // 14px

// border radius
@border-radius-base: 4px; // 2px;
@border-radius-sm: 2px; // @border-radius-base;

// vertical paddings
// @padding-lg: 24px; // containers
// @padding-md: 16px; // small containers and buttons
// @padding-sm: 12px; // Form controls and items
// @padding-xs: 8px; // small items
// @padding-xss: 4px; // more small

// // vertical padding for all form controls
// @control-padding-horizontal: @padding-sm;
// @control-padding-horizontal-sm: @padding-xs;

// vertical margins
// @margin-lg: 24px; // containers
// @margin-md: 16px; // small containers and buttons
// @margin-sm: 12px; // Form controls and items
// @margin-xs: 8px; // small items
// @margin-xss: 4px; // more small

// height rules
// @height-base: 32px;
// @height-lg: 40px;
// @height-sm: 24px;

@btn-font-weight: 500;
// @btn-height-base: 40px;
// @btn-height-lg: 48px;
// @btn-height-sm: 28px;
@btn-font-size-lg: 14px; //@font-size-lg;
@btn-padding-horizontal-lg: 23px; // 14 - 1px

// @dropdown-line-height: 30px;

@checkbox-size: 18px;
@radio-size: 18px;

// @input-height-base: 40px;
// @input-height-lg: 56px;
// @input-height-sm: 28px;
@disabled-color: #b0c0d1;

// Anchor
// ---
// @anchor-bg: transparent;
@anchor-border-color: transparent; //@border-color-split;
// @anchor-link-top: 7px;
// @anchor-link-left: 16px;
// @anchor-link-padding: @anchor-link-top 0 @anchor-link-top @anchor-link-left;

